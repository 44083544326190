import Link from "next/link"
import Image from "next/image"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"
import getTelLink from "utilities/getTelLink"
import Facebook from "public/assets/social/facebook.svg"
import Instagram from "public/assets/social/instagram.svg"
import LinkedIn from "public/assets/social/linkedin.svg"
import Twitter from "public/assets/social/twitter.svg"
import Phone from "public/assets/social/phone.svg"
import Printer from "public/assets/social/printer.svg"
import Logo from "public/assets/logo.svg"

export default function Footer({ globals }) {
  const linkStyles =
    "flex gap-4 items-center justify-center lg:justify-start font-secondary font-medium text-m1 text-white hover:underline hover:text-green transition-all duration-200 mb-2 mx-auto lg:mx-0"
  const menuHeadings = "text-center lg:text-left text-m3 font-medium pb-4"

  return (
    <footer className="main-footer relative z-50">
      <div className="bg-teal-dark px-6 text-white">
        <div className="mx-auto max-w-screen-xl justify-items-center py-12 md:grid md:grid-cols-4 md:gap-y-8 lg:justify-items-start">
          <div className="w-full border-b border-white pb-4 md:col-span-4 md:w-auto md:border-none md:pb-0 lg:col-span-1">
            <Link href="/" className="mx-auto block w-max pb-6 md:mx-0">
              <Logo className="footer-logo h-min w-[225px]" alt="Children's Home Logo" />
            </Link>
            <h3 className={menuHeadings}>Follow Us</h3>
            <div className="mx-auto flex w-max gap-6 md:mx-0">
              <SocialIcon href={globals?.socialMedia?.Twitter} icon="twitter" />
              <SocialIcon href={globals?.socialMedia?.Facebook} icon="facebook" />
              <SocialIcon href={globals?.socialMedia?.Instagram} icon="instagram" />
              <SocialIcon href={globals?.socialMedia?.LinkedIn} icon="linkedin" />
            </div>
          </div>

          <div className="my-4 md:my-0">
            <h3 className={menuHeadings}>Contact</h3>
            <a href={getTelLink(globals?.phoneNumbers?.Main)} className={linkStyles}>
              <Phone /> {globals?.phoneNumbers?.Main}
            </a>
            <a href={getTelLink(globals?.phoneNumbers?.Fax)} className={linkStyles}>
              <Printer /> {globals?.phoneNumbers?.Fax}
            </a>
          </div>

          <nav className="w-full border-y border-white py-4 md:w-auto md:border-none md:py-0">
            <h3 className={menuHeadings}>{globals?.footer?.name}</h3>
            {globals?.footer?.content?.menu_items.map((item) => {
              const href = getStoryblokLink(item.link)
              return (
                <Link
                  href={href}
                  key={item.label}
                  className={linkStyles}
                  target={isInternalLink(href) ? "_self" : "_blank"}
                >
                  {item.label}
                </Link>
              )
            })}
          </nav>

          <div className="grid w-full grid-cols-2 grid-rows-[repeat(2,max-content)] items-center justify-items-center gap-6 px-5 pt-8 md:col-span-2 md:pt-0 lg:col-span-1 lg:gap-11 lg:px-0">
            <Image
              src="/assets/footer-logos/hrc-acaf.webp"
              alt="Human Rights Campaign Foundation"
              width={150}
              height={36}
              className="w-full"
            />
            <Image
              src="/assets/footer-logos/sanctuary-institute.webp"
              alt="Sanctuary Institute"
              width={150}
              height={39}
              className="w-full"
            />
            <Image
              src="/assets/footer-logos/coa.webp"
              alt="Council on Accreditation"
              width={150}
              height={53}
              className="w-full"
            />
            <Image
              src="/assets/footer-logos/ellequate.webp"
              alt="Ellequate"
              width={150}
              height={33}
              className="w-full"
            />
          </div>
        </div>
      </div>

      {/* Disclaimers & Copyright */}
      <div className="bg-springWood px-6">
        <div className="mx-auto flex max-w-screen-xl flex-col items-center gap-y-4 py-6 text-s1 text-teal-dark lg:flex-row lg:py-3">
          <div className="grow text-center lg:text-left">
            © {new Date().getFullYear()} CHNK Behavioral Health. All rights reserved.
          </div>
          <div className="flex divide-x divide-teal-dark">
            <Link href="/privacy-policy" className="pr-4 hover:underline">
              Privacy Notice
            </Link>
            <Link href="/hipaa-notice" className="pl-4 hover:underline">
              HIPAA Notice
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

function SocialIcon({ href, icon }) {
  const Icons = {
    facebook: <Facebook />,
    instagram: <Instagram />,
    twitter: <Twitter />,
    linkedin: <LinkedIn />,
  }
  const Icon = () => Icons?.[icon] ?? null

  return Icon && href ? (
    <Link
      href={href}
      target="_blank"
      className="mx-auto mb-2 flex items-center justify-center gap-4 font-secondary text-m1 font-medium text-white transition-all duration-200 hover:text-green lg:mx-0 lg:justify-start"
    >
      <Icon />
    </Link>
  ) : null
}
